
body {
    padding:0;
    margin:0;
    font-family: 'Roboto', sans-serif;
}

*:focus {
    outline: none;
}

.font_russo {
    font-family: 'Russo One', sans-serif;
}

.bck_black {
    background: #3c3c3c;
}
.bck_white {
    background: #ffffff;
}
.white {
    color: #ffffff;
}

.center_wrapper {
    max-width: 900px;
    margin:0 auto;
}

.section_title {
    text-transform: uppercase;
    text-align: center;
    font-size: 50px;
    margin: 0;
    padding-top: 50px;
}


/*====================
        HEADER
======================*/


header {
    padding:10px 0px;
    transition: all 300ms ease-in !important;
}

header .header_logo {
    flex-grow: 1;
}
header .header_logo_venue {
    font-size: 40px;
}

header .header_logo_title {
    text-transform: uppercase;
}

@media screen and (max-width: 576px) {
    header .header_logo_venue {
        font-size: 24px;
    }
    header .header_logo_title {
        font-size: 14px;
    }
    .section_title {
        font-size: 28px;
    }
}

/*====================
        FOOTER
======================*/

footer {
    padding:50px 0px;
    text-align: center;
    font-size: 60px;
    color:#ffffff;
    background: red;
}

footer .footer_copyright {
    font-size: 18px;
}

@media screen and (max-width: 767px) {
    footer {
        font-size: 30px;
    }
}

/*====================
        VIDEO
======================*/

.video_wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%; 
    overflow: hidden;
}
.video_wrapper .video-js {
    /* Make video to at least 100% wide and tall */
    min-width: 100%; 
    min-height: 100%; 

    /* Setting width & height to auto prevents the browser from stretching or squishing the video */
    width: auto;
    height: auto;

    /* Center the video */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.video_wrapper .video-js video {
    height: unset;
}

/*====================
        AUTHOR
======================*/

.author_h2 {
    text-align: center;
    text-transform: uppercase;
    color:#2c2c2c;
    font-size: 52px;
}

.author_inner_wrapper {
    display: flex;
    padding: 30px 0px 70px;
}

.author_inner_wrapper .author_description {
    width: 60%;
}

.author_inner_wrapper .author_description h3 {
    font-size: 34px;
    font-weight: 100;
    margin: 0px;
    color: #fff;
}

.author_inner_wrapper .author_description p { 
    line-height: 30px;
    font-size: 18px;
    font-weight: 300;
    color: #b8b8b8;
}

.author_inner_wrapper .author_image {
    padding-right: 50px;
    width: 40%;
}

.author_inner_wrapper .author_image img {
    width: 100%;
}

@media screen and (max-width: 960px) {
  .author_inner_wrapper {
      flex-flow: column;
      padding-left: 15px;
      padding-right: 15px;
      align-items: center;
  }
  .author_inner_wrapper .author_image {
      padding-right: 0;
      padding-bottom: 30px;
      width: 50%;
  }
  .author_inner_wrapper .author_description {
      width: 80%;
  }
}
@media screen and (max-width: 767px) {
    .author_inner_wrapper .author_image {
        width: 80%;
    }
    .author_inner_wrapper .author_description {
        width: 100%;
    }
}

/*====================
        BOOK
======================*/

.book_h2 {
    text-align: center;
    text-transform: uppercase;
    color:#2c2c2c;
    font-size: 52px;
}

.book_inner_wrapper {
    display: flex;
    padding: 30px 0px 10px;
}

.book_inner_wrapper .book_description {
    width: 60%;
}

.book_inner_wrapper .book_description h3 {
    font-size: 34px;
    font-weight: 100;
    margin: 0px;
    color: #2c2c2c;
}

.book_inner_wrapper .book_description p { 
    line-height: 30px;
    font-size: 18px;
    font-weight: 300;
    color: #b8b8b8;
}

.book_inner_wrapper .book_image {
    padding-left: 50px;
    width: 40%;
}

.book_inner_wrapper .book_image img {
    width: 100%;
}

@media screen and (max-width: 960px) {
    .book_inner_wrapper {
        flex-flow: column-reverse;
        padding-left: 15px;
        padding-right: 15px;
        align-items: center;
    }
    .book_inner_wrapper .book_image {
        padding-left: 0;
        padding-bottom: 30px;
        width: 50%;
    }
    .book_inner_wrapper .book_description {
        width: 80%;
    }
}
@media screen and (max-width: 767px) {
    .book_inner_wrapper .book_image {
        width: 80%;
    }
    .book_inner_wrapper .book_description {
        width: 100%;
    }
}

/*====================
        READ
======================*/

.read_inner_wrapper {
    display: flex;
    flex-wrap: wrap;
    padding: 30px 0px 70px;
    justify-content: center;
}
.read_inner_wrapper a, .read_inner_wrapper button {
    margin-right: 15px;
    margin-bottom: 15px;
}
.apple_btn {
    display: inline-block;
    overflow: hidden;
    background: url(https://linkmaker.itunes.apple.com/en-us/badge-lrg.svg?releaseDate=2019-11-15T00:00:00Z&kind=ibook&bubble=books) no-repeat;
    width: 141px;
    height: 40px;
}
.google_btn {
    display: inline-block;
    overflow: hidden;
    background-size: contain;
    background-repeat: no-repeat;
    width: 135px;
    height: 40px;
}
.read_btn {
    display: inline-block;
    overflow: hidden;
    background-size: contain;
    background-repeat: no-repeat;
    width: 140px;
    height: 40px;
    background-color: black;
    padding-left: 55px;
    border: 1px solid #a6a6a6;
    border-radius: 5px;
    color: #fff;
    text-decoration: none;
    display: flex;
    align-items: center;
    font-size: 15px;
}
.read_btn:hover {
    cursor: pointer;
}
.epub_btn {
    display: inline-block;
    overflow: hidden;
    background-size: 25px;
    background-position: 10px;
    background-repeat: no-repeat;
    width: 83px;
    height: 38px;
    background-color: black;
    padding-left: 55px;
    border: 1px solid #a6a6a6;
    border-radius: 5px;
    color: #fff;
    text-decoration: none;
    display: flex;
    align-items: center;
    font-size: 15px;
}

/*====================
        LOCATION    
======================*/

.location_wrapper {
    position:relative;
}

.location_tag {
    position: absolute;
    bottom: 0px;
    width: 100%;
}
.location_tag div{
    background: #2c2c2c;
    color: #ffffff;
    text-transform: uppercase;
    width: 80%;
    margin: 0 auto;
    font-size: 29px;
    padding: 15px 20px;
    text-align: center;
}
@media screen and (max-width: 576px) {
    .location_tag div{
        width: 60%;
        font-size: 16px;
    }
}

/*====================
        POPUP    
======================*/

.popup {  
    position: fixed;  
    width: 100%;  
    height: 100%;  
    top: 0;  
    left: 0;  
    right: 0;  
    bottom: 0;  
    margin: auto;  
    background-color: rgba(0,0,0, 0.5);  
    z-index: 99999;
}  
.popup_inner {  
    position: absolute;  
    left: 0;  
    right: 0;  
    top: 0;  
    bottom: 0;  
    margin: auto; 
    background: white;  
}
.close_popup {
    position: absolute;
    z-index: 9999999;
    top: 0;
    right: 0;
    background: transparent;
    border: none;
    margin: 20px;
}
.close_popup:focus {
    outline: none;
}
.close_popup:hover {
    cursor: pointer;
}